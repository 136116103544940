

function Card(props) {
  return (
    <div class="card p-2" style={{width: '28rem'}}>
      <img src={props.img} alt="img"/>
  <div class="card-body">
    <h4 class="card-title p-2">{props.title}</h4>
    <p class="card-text">{props.ServiceResume}</p>
  </div>
  <div class="card-body">
    <a href="#" type="button" class="mx-1 text-decoration-none text-secondary">Portifólios</a><span>|</span>
    <a href="#Contato" class="mx-1 text-decoration-none text-secondary">Entrar em contato</a>
  </div>
</div>
  );
}

export default Card;