
import NavUl from "../Nav/NavUl.js";
function Footer(props) {
  return (
    <div class="container">
  <footer class="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
    <p class="col-md-4 mb-0 text-body-secondary">©PreguicaDe3Dedos 2024 Company, Inc</p>

    <a href="/" class="col-md-4 d-flex align-items-center justify-content-center mb-3 mb-md-0 me-md-auto link-body-emphasis text-decoration-none">
      <svg class="bi me-2" width="40" height="32"></svg>
    </a>
    <nav class=" p-3 navbar navbar-expand-lg ">
   <NavUl/>
   </nav>
  </footer>
</div>
  );
}

export default Footer;