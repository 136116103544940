import logo from './logo.svg';
import './App.css';
import Navbar from './Nav/Nav.js';
import Card from './Components/Card.js';
import Form from './Components/Form.js';
import Footer from './Components/Footer.js';
import Infos from './Components/Infos.js';
import logoD from './images/d365.jpg';
import logoW from './images/website.png';
import bgTop from './images/bg.jpg';
import teste from './images/motionexemple.gif';


function App() {
  return (
    // <div className="App" style={{backgroundColor:"rgba(60, 73, 87, 1)"}}>
    <div className="App">
      <Navbar />
      
      <header className="App-title " style={{ minHeight: '80vh' }}>

        <div class="row d-inline-flex w-100 justify-content-center ">
          <div class="d-flex col-14">
            <img src={teste} class="text-center opacity-75 " style={{ width: '100%' }} alt="..." /><br></br><br></br>
            
            <br />
            <br />
            <br />
          </div>
          {/* <img src={teste} class="text-end opacity-75 " style={{ width: '60%' }} alt="..." /> */}
        </div>
        <br/>
        <br/>
            <a href="#Servicos" class="mx-1 btn btn-dark">Conhecer serviços</a>
    <br/>
        <div  id="Servicos" class="p-5 mx-100">

          <h2 class="p-3">Serviços e portifólios</h2>
          <br />
          <div className="w-75 d-flex container" style={{flexFlow:"wrap"}}>
            <div class="col cards-servicos p-3" style={{ width: 'fit-content', marginBottom: '3em', fontSize: '18px', }} >
              <Card
               img={teste}
                title='Motion Desing'
                ServiceResume='Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ultrices velit quam, eu vehicula ante dictum ut. Maecenas non elit nec velit commodo mollis.'
                item1='Sobre'
                item2='Possibilidades'
                item3='Exemplos'
              />
            </div>
            <div class="col cards-servicos p-3" style={{ width: 'fit-content', marginBottom: '3em', fontSize: '18px' }} >
              <Card
               img={teste}
                title='Animações'
                ServiceResume='Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ultrices velit quam, eu vehicula ante dictum ut. Maecenas non elit nec velit commodo mollis.'
                item1='Sobre'
                item2='Possibilidades'
                item3='Exemplos'
              />
            </div>

            <div class="col cards-servicos p-3" style={{ width: 'fit-content', marginBottom: '3em', fontSize: '18px' }} >
              <Card
               img={teste}
                title='Modelagem/Textura 3D'
                ServiceResume='Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ultrices velit quam, eu vehicula ante dictum ut. Maecenas non elit nec velit commodo mollis.'
                item1='Sobre'
                item2='Possibilidades'
                item3='Exemplos'
              />
            </div>
            <div class="col cards-servicos p-3" style={{ width: 'fit-content', marginBottom: '3em', fontSize: '18px' }} >
              <Card
               img={teste}
                title='Ilustração'
                ServiceResume='Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ultrices velit quam, eu vehicula ante dictum ut. Maecenas non elit nec velit commodo mollis.'
                item1='Sobre'
                item2='Possibilidades'
                item3='Exemplos'
              />
            </div>

          </div>
        </div>

      </header>
      <body >
        <br/>
        <br/>
        <h3>Entre em contato com nossa equipe e tire todas suas dúvidas</h3>
        <br/>
        <br/>
        <div className='container'>

        <div id="Contato" class="w-100">
        <Infos
        img={teste}
        />
        <br/>
        <br/>

        <Form/>
     
        </div>
        </div>
      </body>
      <Footer/>
    </div>
  );
}

export default App;
